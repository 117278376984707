import React from "react";
import styled from "styled-components";

const index = (props) => {
  return (
    <StyleContainer>
      <h1>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</h1>

      <p>
        A LIQUID TECNOLOGIA LTDA (LIQUID TECH), pessoa jurídica inscrita no
        CNPJ/ME sob o nº 34.539.785/0001-97, tem como propósito estabelecer
        nesta oportunidade seus Termos de Uso e sua Política de Privacidade
        (Termos), por meio das quais o Usuário e/ou titular de dados pessoais
        poderá compreender, facilmente, as condições de uso do aplicativo “CAIU
        O PIX?”.
      </p>

      <p>
        A LIQUID TECH acredita ainda que a privacidade, a segurança e a
        confiança são fatores fundamentais para a relação com os seus usuários,
        tendo os presentes Termos também como finalidade a transparência com
        relação a coleta, uso e transferência de informações; de modo a afirmar
        o compromisso da LIQUID com os Usuários e o cumprimento das normas de
        Proteção de Dados (LGPD, Lei Federal 13.709/2018) e demais normas do
        ordenamento jurídico brasileiro aplicáveis.
      </p>

      <p>1. DEFINIÇÕES: Para os fins destes Termos, consideram-se:</p>

      <p>
        1.1. Internet: o sistema constituído do conjunto de protocolos lógicos,
        estruturado em escala mundial para uso público e irrestrito, com a
        finalidade de possibilitar a comunicação de dados entre terminais por
        meio de diferentes redes.
      </p>

      <p>
        1.2. Aplicativo: para fins dos presentes Termos, significa
        exclusivamente o aplicativo “CAIU O PIX?”.
      </p>

      <p>
        1.3. Login: credencial previamente cadastrada pelo Usuário, que
        possibilita sua identificação para acesso ao aplicativo.
      </p>

      <p>
        1.4. Senha: conjunto de caracteres que podem ser constituídos por letras
        e/ou números, com a finalidade de verificar a identidade do Usuário para
        acesso ao aplicativo.
      </p>

      <p>
        1.5. Usuários (ou “Usuário”, quando individualmente considerado):
        qualquer pessoa jurídica e/ou seu representante legal (pessoa física)
        que tenha fornecido as informações necessárias para a realização do
        cadastro e utilização da plataforma.
      </p>

      <p>
        1.6. PIX: arranjo de pagamentos instituído pelo Banco Central do Brasil,
        que possibilita ao usuário efetuar pagamentos, realizar transferências e
        receber valores de forma instantânea, 24 (vinte e quatro) horas por dia.
      </p>

      <p>
        1.7. Chave PIX: informação relacionada ao titular de uma conta bancária,
        que permite obter informações sobre o titular e sua respectiva conta,
        com a finalidade de facilitar a iniciação de processos de pagamentos.
      </p>

      <p>
        2. ACEITE DOS TERMOS DE USO E POLÍTICA DE PRIVACIDADE: Ao ler estes
        Termos e dar início à utilização do APP, o Usuário declara estar ciente
        e ter compreendido as regras e condições ora estipuladas. Caso haja
        alguma dúvida sobre quaisquer aspectos relacionados a este instrumento,
        o Usuário poderá solicitar esclarecimentos através dos nossos canais de
        atendimento.
      </p>

      <p>
        Em razão do constante desenvolvimento e aprimoramento da plataforma, a
        LIQUID TECH se reserva o direito de modificar, a qualquer tempo, o
        disposto nestes Termos, sendo que sempre que houver alterações
        relevantes, o Usuário será notificado, sendo-lhe exigida nova leitura e
        novo aceite.
      </p>

      <p>
        Caso o Usuário já esteja utilizando o aplicativo e passe a discordar com
        o previsto nestes Termos e/ou em suas atualizações, poderá cancelar sua
        conta/assinatura através de solicitação feita por qualquer dos nossos
        canais de atendimento.
      </p>

      <p>
        3. OBJETO: A LIQUID TECH oferece ao Usuário, por meio de assinatura
        mensal, a utilização de um software (“CAIU O PIX?”) que auxilia na
        realização e confirmação de transações efetuadas através do PIX. Por
        meio do APP, o Usuário tem a confirmação instantânea de que o PIX
        solicitado foi efetivamente realizado, sem precisar acessar a sua
        Instituição Bancária, contando, ainda, com um extrato de todas as
        confirmações de PIX realizadas em sua conta, com data, hora e valor de
        cada transação.
      </p>

      <p>
        4. CADASTRO: O APP pode possuir tanto áreas de conteúdo aberto como de
        conteúdo e/ou serviço restritos. Assim, para que o Usuário acesse tais
        conteúdos e/ou serviços restritos, será necessário realizar um cadastro,
        destinado à criação de um perfil com login e senha, e, para tanto, do
        fornecimento de alguns dados, tais como: nome completo, telefone, CNPJ,
        razão social, nome fantasia e chave PIX (“Dados de Cadastro”). Caso seja
        necessário para a inequívoca identificação do Usuário, outros dados de
        cadastro poderão ser solicitados.
      </p>

      <p>
        4.1. O Usuário se responsabiliza pela precisão e veracidade dos dados
        informados e reconhece que a inconsistência destes poderá implicar na
        impossibilidade de acessar e/ou utilizar o aplicativo.
      </p>

      <p>
        4.2. O login e senha só poderão ser utilizados pelo Usuário cadastrado,
        sendo expressamente proibido o compartilhamento de tais informações com
        quaisquer terceiros.
      </p>

      <p>
        4.3. O Usuário assume inteira responsabilidade pela guarda, sigilo e boa
        utilização do login e senha cadastrados, isentando a LIQUID TECH de
        qualquer responsabilidade.
      </p>

      <p>
        5. LINKS DE TERCEIROS: O App poderá conter links para sites e
        aplicativos de terceiros. Note que dentro destes sites e aplicativos
        você estará sujeito a outros termos de uso e a outras políticas de
        privacidade. Nossos Termos de Uso e Política de Privacidade não são
        válidos nos sites e aplicativos de terceiros. A existência desses links
        não significa nenhuma relação de endosso ou de patrocínio entre a LIQUID
        TECH e esses terceiros, e a LIQUID não tem nenhuma responsabilidade com
        relação a tais terceiros.
      </p>

      <p>
        6. COMO NOSSO APP NÃO DEVE SER UTILIZADO: O Usuário não poderá praticar
        as seguintes ações em razão ou por meio da utilização do APP:
      </p>

      <p>
        6.1. prática de quaisquer atos ilícitos, violar direitos da LIQUID TECH
        ou de terceiros ou violar a legislação vigente, de qualquer forma;
      </p>

      <p>6.2. atos contrários à moral e aos bons costumes;</p>

      <p>6.3. violação dos direitos de sigilo e privacidade alheios;</p>

      <p>
        6.4 usar qualquer sistema/aplicação automatizada para realizar
        consultas, acessos ou qualquer outra operação massificada, para qualquer
        finalidade, sem autorização da LIQUID TECH;
      </p>

      <p>
        6.5. atos que causem ou propiciem a contaminação ou prejudiquem o APP ou
        quaisquer equipamentos da LIQUID TECH, de suas afiliadas e/ou de
        terceiros, inclusive por meio de vírus, trojans, malware, worm, bot,
        backdoor, spyware, rootkit, ou por qualquer outro meio com este fim;
      </p>

      <p>
        6.6. usar qualquer nome empresarial, marca, nome de domínio, slogan ou
        expressão de propaganda ou qualquer sinal distintivo ou bem de
        propriedade intelectual de titularidade da LIQUID TECH.
      </p>

      <p>
        7. PROPRIEDADE INTELECTUAL: Pertence a LIQUID TECH (i) todo e qualquer
        software, aplicativo ou funcionalidade por ela empregada no APP; (ii) a
        identidade visual do APP (incluindo o projeto de arte gráfico-visual de
        quaisquer de suas guias ou páginas); (iii) o nome empresarial, marca,
        nome de domínio, slogan ou expressão de propaganda ou qualquer sinal
        distintivo de sua titularidade inserido no APP; e (iv) todo e qualquer
        conteúdo criado e produzido pela LIQUID TECH, por si ou por terceiros,
        os quais não poderão ser usados, sob qualquer meio ou forma, pelos
        Usuários.
      </p>

      <p>
        8. PRIVACIDADE: O aplicativo “CAIU O PIX?” coleta apenas as informações
        necessárias para a criação do cadastro do Usuário, já descritas no
        tópico 4. Nesse sentido, o Usuário está ciente e concorda com a coleta,
        armazenamento e tratamento dos referidos dados, nos limites
        estabelecidos nestes Termos de Uso e Política de Privacidade.
      </p>

      <p>
        8.1. As informações dos Usuários são coletadas, armazenadas e tratadas
        pela LIQUID TECH exclusivamente para as seguintes finalidades:
      </p>

      <p>
        8.1.1. Permitir a comunicação entre os Usuários e a LIQUID TECH,
        inclusive com o fim de possibilitar o tratamento de dúvidas,
        reclamações, sinistros, solicitações e suporte, de modo a atender os
        Usuários sempre que necessário;
      </p>

      <p>
        8.1.2. Possibilitar o acesso e o uso dos recursos e funcionalidades do
        APP pelos Usuários;
      </p>

      <p>
        8.1.3. Analisar o desempenho do aplicativo, avaliar estatísticas
        relacionadas ao número de acessos e uso do aplicativo, seus recursos e
        funcionalidades;
      </p>

      <p>
        8.1.4. Realizar análises relacionadas à segurança do aplicativo,
        aperfeiçoamento e desenvolvimento de ferramentas antifraude;
      </p>

      <p>8.1.5. Melhorar as experiências de navegação dos Usuários;</p>

      <p>
        8.1.6. Permitir o fornecimento de serviços mais personalizados e
        adequados às necessidades dos Usuários;
      </p>

      <p>
        8.1.7. Desenvolver, manter e aperfeiçoar os recursos e funcionalidades
        do APP;
      </p>

      <p>
        8.2. As informações de cadastro do Usuário serão armazenadas até que a
        relação contratual entre ele e a LIQUID TECH termine e pelo período de 5
        (cinco) anos após o seu término, a fim de possibilitar a execução do
        contrato estabelecido entre as partes e resguardar os direitos do
        Usuário.
      </p>

      <p>
        8.3. A LIQUID TECH preserva a privacidade dos Usuários e não compartilha
        seus dados e informações com terceiros sem o seu consentimento, salvo
        quando estritamente necessário para o cumprimento do contrato,
        observando, em todo caso, padrões rígidos de segurança e a
        confidencialidade das informações do Usuário.
      </p>

      <p>
        8.4. A segurança e a proteção dos dados pessoais e informações do
        Usuário é uma prioridade para LIQUID TECH. Por esta razão, a LIQUID
        estabelece diversos procedimentos e controles internos destinados à
        prevenção, detecção e resposta a incidentes, assim como à proteção dos
        dados tratados através do aplicativo.
      </p>

      <p>
        8.5. O Usuário poderá, a qualquer momento, excluir sua conta do APP,
        ficando ressalvada a guarda pela LIQUID TECH pelo prazo previsto no item
        8.2. acima e/ou das informações e/ou dados cuja manutenção lhe seja
        imposta em razão de obrigações legais e/ou regulatórias ou, ainda, cuja
        manutenção seja necessária para o exercício regular de direitos da
        LIQUID TECH em processos e/ou procedimentos judiciais, administrativos
        ou arbitrais.
      </p>

      <p>9. RESPONSABILIDADES</p>

      <p>9.1. Em nenhuma hipótese, a LIQUID TECH será responsável:</p>

      <p>
        9.1.1. por quaisquer problemas de conexão e/ou acesso à Internet
        eventualmente enfrentados pelo Usuário;
      </p>

      <p>
        9.1.2. por qualquer ato ou omissão realizado e/ou dano causado pelo
        Usuário decorrente do acesso ao aplicativo;
      </p>

      <p>
        9.1.3. por qualquer ato de negligência, imprudência ou imperícia do
        Usuário no trato com seus dados individuais, comprometendo-se a
        garantir, no entanto, a segurança dos dados por ela coletados,
        armazenados e tratados.
      </p>

      <p>
        9.1.4. pelo uso indevido do APP por qualquer Usuário ou terceiros e/ou
        pelos conteúdos eventualmente carregados, enviados e/ou transmitido ao
        aplicativos pelos Usuários;
      </p>

      <p>
        9.1.5. por falhas, impossibilidades técnicas ou indisponibilidades do
        sistema;
      </p>

      <p>
        9.1.6. pela instalação no equipamento do Usuário ou de terceiros, de
        vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de
        quaisquer outros dispositivos que venham a ser criados, em decorrência
        da navegação na Internet pelo Usuário;
      </p>

      <p>
        9.2. O Usuário declara estar ciente de que todos e quaisquer conteúdos
        enviados e/ou transmitidos ao APP por outros Usuário e/ou terceiros não
        representam, de nenhuma forma, a opinião ou a visão da LIQUID TECH.
      </p>

      <p>
        9.3. A LIQUID TECH não é responsável por intermediar os pagamentos
        realizados através do PIX, atuando, unicamente, por meio da apresentação
        de comprovante de que a transação realizada por meio dos prestadores de
        serviços de pagamentos escolhidos pelo Usuário e seus consumidores foi
        efetivamente concluída.
      </p>

      <p>
        9.4. A LIQUID TECH também não se responsabiliza por eventuais problemas
        existentes nos sistemas dos prestadores de serviço de pagamento ou por
        eventual bloqueio cautelar por eles realizado, por suspeita de fraude,
        que impossibilite a apresentação imediata do comprovante da transação.
      </p>

      <p>
        9.5. Caso o Usuário descumpra qualquer disposição destes Termos de Uso e
        Política de Privacidade ou disposição legal, sem prejuízo de outras
        medidas, a LIQUID TECH, a qualquer tempo, sem necessidade de qualquer
        aviso ou notificação prévia ou posterior do Usuário e sem prejuízo de
        iniciar as medidas legais cabíveis, poderá suspender ou limitar o acesso
        ao aplicativo, encerrar a conta do Usuário e/ou tomar outras
        providências que entender necessárias para o cumprimento destes Termos
        de Uso e Política de Privacidade e para o bom funcionamento do APP.
      </p>

      <p>
        10. TOLERÂNCIA: A tolerância quanto ao eventual descumprimento de
        quaisquer das disposições destes Termos de Uso e Política de Privacidade
        por qualquer Usuário não constituirá renúncia ao direito de exigir o
        cumprimento da obrigação, nem perdão, nem alteração do quanto aqui
        previsto.
      </p>

      <p>
        11. ALTERAÇÕES DO ACESSO E DOS TERMOS DE USO E POLÍTICAS DE PRIVACIDADE:
        A LIQUID TECH, a qualquer tempo, a seu exclusivo critério e sem
        necessidade de qualquer aviso prévio ou posterior a qualquer Usuário ou
        terceiros, poderá: (i) suspender, cancelar ou interromper o acesso ao
        APP e (ii) remover, alterar e/ou atualizar no todo ou em parte o
        aplicativo bem como seus respectivos conteúdos e/ou os presentes Termos
        de Uso e Política de Privacidade. Qualquer alteração e/ou atualização
        destes Termos de Uso e Política de Privacidade passará a vigorar a
        partir da data de sua publicação e deverá ser integralmente observada
        pelos Usuários.
      </p>

      <p>
        12. CONTATO: Qualquer dúvida ou solicitação do Usuário a respeito dos
        presentes Termos poderá ser encaminhada através do endereço de e-mail:
      </p>

      <p>
        13. LEGISLAÇÃO E FORO: Estes Termos de Uso e Política de Privacidade são
        regidos de acordo com a legislação brasileira. Quaisquer disputas ou
        controvérsias oriundas de quaisquer atos praticados no âmbito da
        utilização do aplicativo pelos Usuários, inclusive com relação ao
        descumprimento dos Termos de Uso e Política de Privacidade ou à violação
        dos direitos da LIQUID TECH, de outros Usuários e/ou de terceiros,
        inclusive direitos de propriedade intelectual, de sigilo e de
        personalidade, serão processadas na Comarca da Capital do Estado de São
        Paulo.
      </p>
    </StyleContainer>
  );
};

export default index;

const StyleContainer = styled.div`
  margin: 0 20px;
`;
